import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import AnimationRevealPage from '../../components/AnimationRevealPage';

import TextDiplay1 from '../../components/TextDisplay1';

export default function NotreIdentite() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collège Jules Verne Kairouan - À propos"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />
        <TextDiplay1 />
      </Layout>
    </AnimationRevealPage>
  );
}
