import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function TextDiplay1() {
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto text-center">
          <span class="relative inline-block text-blue-900">Qui sommes nous ?</span>
        </h2>
        <div class="flex mt-6 justify-center">
          <div class="w-16 h-1 rounded-full bg-blue-900 inline-flex"></div>
        </div>
      </div>
      <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
        <div className="grid grid-cols-2 gap-5">
          <StaticImage
            className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
            alt=""
            src="../images/etablissement-3.webp"
          />
          <StaticImage
            className="object-cover w-full h-48 rounded shadow-lg"
            alt=""
            src="../images/etablissement-2.webp"
          />
          <StaticImage
            className="object-cover w-full h-48 rounded shadow-lg"
            alt=""
            src="../images/etablissement-4.webp"
          />
        </div>
        <div className="flex flex-col justify-center">
          <div className="pb-4 mb-4 border-b">
            <p className="text-lg text-gray-900 md:text-xl">
              Le Collège Français de Kairouan (CFK) : Etablissement Jules Verne, est un collège
              partenaire du réseau AEFE et fonctionnant selon les programmes officiels de
              l’Education Nationale française.
            </p>
          </div>
          <div className="pb-4 mb-4 border-b">
            <p className="text-lg text-gray-900 md:text-xl">
              En Septembre 2022, l'établissement accueillera les élèves de la classe de 6ème jusqu'
              à la 4ème.
            </p>
          </div>
          <div>
            <p className="text-lg text-gray-900 md:text-xl">
              Chaque classe comptera au maximum un effectif de 25 élèves par classe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
